import React from "react";

import { PageContainer } from "../../components/container";
import { Helmet } from "react-helmet";

import { graphql } from "gatsby";

import { eventURL, hourOutput, dateString } from "../../components/event";
import { Grid, Typography, Button } from "@material-ui/core";
import { GatsbyImage } from "gatsby-plugin-image";

export const query = graphql`
query EventQuery ($slug: String) {
    allStrapiEvents (
      filter: {
        slug: { eq: $slug },
        title: { ne: null }
      }
    ) {
      edges {
        node {
          id
          title
          url
          start
          end
          slug
  
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 640)
              }
            }
          }
          
          organizer {
            id
            name
            url
          }
          venue {
            id
            name
            address
            latitude
            longitude
          }
        }
      }
    }
  }
`;

const Main = ({ data }) => {
  if (data.allStrapiEvents.edges.length < 1 || !data.allStrapiEvents.edges[0].node) {
    return (<React.Fragment></React.Fragment>);
  }
  const event = data.allStrapiEvents.edges[0].node;
  const cardData = [
    {
      "@context": "http://schema.org/",
      "@type": "Organization",
      "name": "Rave Texas",
      "url": "https://ravetexas.com/",
      "logo": "https://ravetexas.com/icons/icon-512x512.png",
      "sameAs": [
        "http://twitter.com/rave_texas"
      ]
    },
    // {
    //     "@context": "http://schema.org/",
    //     "@type": "WebSite",
    //     "url": "https://ravetexas.com/",
    //     "potentialAction": {
    //         "@type": "SearchAction",
    //         "target": "https://ravetexas.com/search?q={search_term_string}",
    //         "query-input": "required name=search_term_string"
    //     }
    // },
    {
      "@context": "http://schema.org",
      "@type": "Event",
      "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
      "eventStatus": "https://schema.org/EventScheduled",
      "location": {
        "@type": "Place",
        "address": event.venue.address,
        "name": event.venue.name
      },
      "organizer": {
        "@type": "Organization",
        "name": event.organizer.name,
        "url": event.organizer.url
      },
      "name": event.title,
      "startDate": event.start,
      "endDate": event.end,
      "image": event.cover.localFile.childImageSharp.gatsbyImageData.images.fallback.src
    }
  ];
  return (<React.Fragment>
    <Helmet>
      <title>{`${event.title} @ ${event.venue.name}`}</title>
      <script type="application/ld+json">{JSON.stringify(cardData)}</script>
      <meta property="og:url" content={`https://ravetexas.com${eventURL(event)}`} />
      <meta property="og:title" content={`${event.title}`} />
      <meta property="og:description" content={`${event.title} @ ${event.venue.name}`} />
      <meta property="og:image" content={`https://ravetexas.com${event.cover.localFile.childImageSharp.gatsbyImageData.images.fallback.src}`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@rave_texas" />
      <meta name="twitter:creator" content="@rave_texas" />
      <meta name="twitter:image" content={`https://ravetexas.com${event.cover.localFile.childImageSharp.gatsbyImageData.images.fallback.src}`} />
      <meta name="twitter:label1" value="Starts" />
      <meta name="twitter:data1" value={`${dateString(event.start)} @ ${hourOutput(event.start)}`} />
      <meta name="twitter:label2" value="Tickets" />
      <meta name="twitter:data2" value={event.url} />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1633320757911200"
        crossorigin="anonymous"></script>
    </Helmet>
    <PageContainer>
      <Grid container alignItems="center" justifyContent="center" style={{ paddingBottom: '40px' }}>
        <Grid item xs={12} style={{ textAlign: "center", paddingTop: "40px" }}>
          <Typography variant="h2" >{event.title}</Typography>
        </Grid>
        <Grid item xs={12} style={{ padding: "10px", textAlign: "center" }}>
          <GatsbyImage
            image={event.cover.localFile.childImageSharp.gatsbyImageData}
            title={event.title}
            alt={event.title}
            imgStyle={{ padding: "15px" }}
          ></GatsbyImage>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
          <Typography variant="h4">{event.venue.name}</Typography>
          <Typography variant="body1">{event.venue.address}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
          <Typography variant="body1">{`${dateString(event.start)} @ ${hourOutput(event.start)}`}</Typography>
          <Typography variant="body2">through</Typography>
          <Typography variant="body1">{`${dateString(event.end)} @ ${hourOutput(event.end)}`}</Typography>
          <Typography variant="body1" style={{ paddingTop: "20px" }}>Presented by: {event.organizer.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ textAlign: "center" }}>
          <Button variant="contained" href={event.url} target="_blank">Buy Tickets</Button>
        </Grid>
      </Grid>
    </PageContainer>
  </React.Fragment>);
};

export default Main;